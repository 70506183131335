import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import VideoSection from "../components/videoSection"

export const BeschwerdenPostTemplate = ({
					content,
					videoURL,
					image,
					helmet,
				}) => {
					return (
						<>
							{helmet || ""}
							<VideoSection herovideo={videoURL} heroimage={image} />

							<section className="section">
								<div className="container content">
									<div className="columns">
										<div className="column">
											<HTMLContent content={content} />
										</div>
									</div>
								</div>
							</section>
						</>
					)
				}

BeschwerdenPostTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	videoURL: PropTypes.string,
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	description: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
}

const BeschwerdenPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BeschwerdenPostTemplate
        content={post.html}
        videoURL={post.frontmatter.videoURL}
        image={post.frontmatter.image}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="Beschwerden | %s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BeschwerdenPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BeschwerdenPost

export const pageQuery = graphql`
					query BeschwerdenPostByID($id: String!) {
						markdownRemark(id: { eq: $id }) {
							id
							html
							frontmatter {
								date(formatString: "MMMM DD, YYYY")
								title
								description
								tags
								videoURL
								image {
									childImageSharp {
										fluid(maxWidth: 1520, quality: 94) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
									publicURL
								}
							}
						}
					}
				`
